import type { GraphQLFormattedError } from 'graphql';
import { type FC } from 'react';

interface GqlErrorDisplayProps {
  errors: readonly GraphQLFormattedError[];
}

export const GqlErrorDisplay: FC<GqlErrorDisplayProps> = ({ errors }) => (
  <ul style={{ paddingLeft: '10px' }}>
    {errors?.length ? (
      errors.map((e, i) => (
        <li style={{ listStyleType: 'disc' }} key={`${e.message}-${i}`}>
          {e.message}
        </li>
      ))
    ) : (
      <li style={{ listStyleType: 'disc' }}>Something went wrong...</li>
    )}
  </ul>
);
